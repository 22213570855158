// WARNING: slots defined here will be defined without care if the slot is actually displayed in the end
// check any edits with googletag.openConsole()
// slot must be used in markup in order for valid define - load - display sequence of events
// if you wish to define/add a new slot on a page, make sure that it's added both here and in the markup
// eg. <AdSlot {...staticAds.halfpage1} />
// or deconstruct/reconstruct the staticAds object before passing it to useDfpSlots
const staticAds = {
    halfpage1: {
        alternateBg: true,
        maxAdWidth: '300px',
        position: 'Floating - 1',
        isSliding: true,
        slidingProps: {
            offsetTop: 70,
            contentWidth: '100%'
        }
    },
    halfpage2: {
        alternateBg: true,
        maxAdWidth: '300px',
        position: 'Floating - 2',
        isSliding: true,
        slidingProps: {
            offsetTop: 70,
            offsetBottom: 50,
            contentWidth: '100%',
            renderInSmallSpace: false,
            minimumFrameHeight: 600
        }
    },

    halfpage2alt: {
        alternateBg: true,
        maxAdWidth: '300px',
        position: 'Floating - 2',
        isSliding: true,
        slidingProps: {
            offsetTop: 70,
            offsetBottom: 50,
            contentWidth: '100%'
        }
    },
    halfpage3: {
        alternateBg: true,
        maxAdWidth: '300px',
        position: 'Floating - 3',
        isSliding: false
    },
    billboard1: {
        amp: true,
        position: 'Billboard - 1'
    },
    billboard2: {
        disablePlaceholder: true,
        disableFixedHeight: true,
        amp: true,
        position: 'Billboard - 2'
    },
    outOfPage1: {
        desktopOnly: true,
        onePxSlot: true,
        renderOutOfPage: true,
        position: 'Wallpaper - left' // @TODO replace with OOP cms input once available
    },
    outOfPage2: {
        desktopOnly: true,
        onePxSlot: true,
        renderOutOfPage: true,
        position: 'Wallpaper - right' // @TODO replace with OOP cms input once available
    },
    outOfPage3: {
        mobileOnly: true,
        onePxSlot: true,
        renderOutOfPage: true,
        position: 'Floating' // @TODO replace with OOP cms input once available
    },
    anchor: {
        // onePxSlot: true,
        renderOutOfPage: true,
        position: 'Hardcoded Anchor'
    },
    interstitial: {
        // mobileOnly: true,
        // onePxSlot: true,
        renderOutOfPage: true,
        position: 'Hardcoded Interstitial'
    },
    galleryMobileRectangle: {
        mobileOnly: true,
        position: 'Hardcoded Gallery - Mobile Rectangle'
    },
    inGallery1: {
        disablePlaceholder: true,
        disableFixedHeight: true,
        position: ['In gallery - 1', 'In gallery - 1 - alt']
    },
    inGallery2: {
        desktopOnly: true,
        disablePlaceholder: true,
        disableFixedHeight: true,
        position: ['In gallery - 2', 'In gallery - 2 - alt']
    },
    inGallery3: {
        disablePlaceholder: true,
        disableFixedHeight: true,
        position: ['In gallery - 3', 'In gallery - 3 - alt']
    }
}
export { staticAds }
