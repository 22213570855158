import { useMemo } from 'react'
import { useUIDSeed } from 'react-uid'
import PropTypes from 'prop-types'

import { Column, Row } from '@hmn/rtl-web-core/components/layout'
import { Media } from '@hmn/rtl-web-core/components/Media'
import { useVideoAdList } from '@hmn/rtl-web-core/context/ads/manifest.context'
import { useCrossDomainCommunication as usePreview, useVideoThumbnail } from '@hmn/rtl-web-core/hooks'
import useEntityTypeDispatch from '@hmn/rtl-web-core/hooks/entity/useEntityTypeDispatch'
import { IsolatedEntityThirdParties } from '@hmn/rtl-web-core/hooks/IsolatedEntityThirdParties'

import { staticAds } from '@hmn/rtl-net-ui/components/Ad/config/article'
import AdSlot from '@hmn/rtl-net-ui/components/Ad/NetSlot.component'
import {
    ArticleCard,
    articleCardContentMarkerVariants,
    articleCardSizeVariants,
    articleCardVariants,
    ArticleCtaBlock,
    ArticleTags
} from '@hmn/rtl-net-ui/components/Article/components'
import { GalleryCardsBlock } from '@hmn/rtl-net-ui/components/Gallery/components'
import { Meta } from '@hmn/rtl-net-ui/components/Meta'
import { VideoContent } from '@hmn/rtl-net-ui/components/Video/components'

import { BaseLayout } from '../../layouts'
import { Seo } from '..'
import { MostViewedVideos } from '../MostViewedVideos/MostViewedVideos'

const { halfpage1, halfpage2, billboard1, anchor, outOfPage1, outOfPage2, outOfPage3, interstitial } = staticAds

const halfpageSlidingProps = {
    offsetTop: 80,
    offsetBottom: 50,
    contentWidth: '100%',
    minimumFrameHeight: 600
}

const gtmDataNew = {
    eventCategory: 'Video',
    eventAction: 'Najnovije',
    eventLabel: 'Below content'
}

function Video({ ssrData, navigation }) {
    const uid = useUIDSeed()
    const { videoElastic } = ssrData
    const { id } = videoElastic
    useEntityTypeDispatch('video')

    const thumbnail = useVideoThumbnail({ videoElastic })

    const videoAds = useVideoAdList()
    const { data: video } = usePreview(videoElastic, { type: 'preview', source: '@CMS' })

    const { latestVideos } = ssrData

    // const videoDate = dayjs(videoElastic?.published_at).utc().tz('Europe/Zagreb').format('D.M.YYYY.')

    const videoTags = useMemo(() => video?.tags || videoElastic.tags || [], [video?.tags, videoElastic.tags])

    return (
        <BaseLayout navigationData={navigation}>
            <IsolatedEntityThirdParties
                entity={video}
                entityType="video"
                appName="net.hr"
                defaultGemiusId={process.env.NEXT_PUBLIC_GEMIUS_ID_NET}
                appRoot={process.env.NEXT_PUBLIC_APP_ROOT_NET}
                ampGtmId={process.env.NEXT_PUBLIC_AMP_GTM_ID_NET}
            />
            <Seo mainEntity item={video} />
            <Column variant="media.main">
                <Row>
                    <Column variant="media.content">
                        <Column>
                            <VideoContent
                                ads={videoAds}
                                id={id}
                                inViewAutoplay
                                isMainContent
                                key={videoElastic?.id}
                                lead={video?.description}
                                subtitle={video?.subtitle || video?.extended_attributes?.subtitle}
                                thumbnail={thumbnail}
                                title={video?.name}
                                sticky
                                url={
                                    video?.extended_attributes?.stream_url ||
                                    video?.original_url ||
                                    video?.custom_manifest_url
                                }
                            />
                        </Column>
                        {video?.extended_attributes?.cta_title && video?.extended_attributes?.cta_url ? (
                            <Column variant="media.cta">
                                <ArticleCtaBlock
                                    content={video?.extended_attributes?.cta_title}
                                    title="Saznaj više o temi"
                                    href={video?.extended_attributes?.cta_url}
                                    buttonTitle={video?.extended_attributes?.cta_button_label}
                                />
                            </Column>
                        ) : null}
                    </Column>
                    <Column variant="media.main.aside">
                        <Media className="Sidebar_aside" greaterThan="sm" scaleDown>
                            <AdSlot {...halfpage1} />
                        </Media>
                    </Column>
                </Row>
                <Column variant="media.meta">
                    <Meta
                        authorSlug={videoElastic?.taxons?.authors?.[0]?.slug}
                        author={
                            videoElastic?.taxons?.authors?.[0]?.title ||
                            videoElastic?.extended_attributes?.author ||
                            'net.hr'
                        }
                        authors={videoElastic?.taxons?.authors || videoElastic?.extended_attributes?.author || []}
                        photoAuthor={video?.extended_attributes?.source}
                        date={videoElastic?.published_at}
                    />
                    <ArticleTags tags={videoTags} />
                </Column>
                <Row variant="media.relatedMobile">
                    <Column variant="media.related.latest">
                        <GalleryCardsBlock title="Najnovije" titleTagComponent="h4" href="/media/video" isVideo>
                            {latestVideos
                                ?.filter(item => item?.id !== id)
                                ?.slice(0, 10)
                                ?.map((item, index) => (
                                    <ArticleCard
                                        contentMarkerVariant={articleCardContentMarkerVariants.VIDEO}
                                        item={item}
                                        key={uid(item?.id || index)}
                                        href={item?.sitemap?.href}
                                        variant={articleCardVariants.ALPHA}
                                        sizeVariant={articleCardSizeVariants['1x2']}
                                        imageWidth={700}
                                        imageHeight={466}
                                        gtmData={gtmDataNew}
                                        titleTagComponent="h3"
                                    />
                                ))}
                        </GalleryCardsBlock>
                    </Column>
                    <Column variant="media.related.popular">
                        <Media lessThan="md" scaleDown>
                            <Row variant="media.ads.billboard">
                                <AdSlot {...billboard1} />
                            </Row>
                        </Media>
                        <Row>
                            <MostViewedVideos excludeId={video.id} />
                        </Row>
                        <Row variant="media.ads.floating">
                            <Media className="Sidebar_aside" greaterThan="sm" scaleDown>
                                <AdSlot {...halfpage2} isSliding slidingProps={halfpageSlidingProps} />
                            </Media>
                        </Row>
                    </Column>
                </Row>
            </Column>
            <Row variant="zeroDimensions">
                <AdSlot {...outOfPage1} aPropThatDoesSomething />
                <AdSlot {...outOfPage2} />
                <AdSlot {...outOfPage3} />
                <AdSlot {...anchor} />
                <AdSlot {...interstitial} />
            </Row>
        </BaseLayout>
    )
}

Video.propTypes = {
    themeName: PropTypes.string.isRequired,
    ssrData: PropTypes.shape({
        videoElastic: PropTypes.shape({
            sitemap: PropTypes.shape({
                node: PropTypes.shape({}),
                href: PropTypes.string
            }),
            published_at: PropTypes.string,
            tags: PropTypes.arrayOf(PropTypes.shape({})),
            taxons: PropTypes.shape({
                sitemap: PropTypes.arrayOf(
                    PropTypes.shape({
                        path: PropTypes.arrayOf(
                            PropTypes.shape({
                                title: PropTypes.string
                            })
                        )
                    })
                ),
                authors: PropTypes.arrayOf(
                    PropTypes.shape({
                        title: PropTypes.string,
                        slug: PropTypes.string
                    })
                )
            }),
            extended_attributes: PropTypes.shape({
                author: PropTypes.shape({})
            }),
            creator: PropTypes.shape({
                display_name: PropTypes.string
            }),
            id: PropTypes.string,
            title: PropTypes.string,
            name: PropTypes.string
        }).isRequired,
        latestVideos: PropTypes.arrayOf(PropTypes.shape({})).isRequired
    }).isRequired,
    context: PropTypes.shape({
        embeds: PropTypes.shape({
            entities: PropTypes.shape({}).isRequired
        }).isRequired
    }).isRequired,
    navigation: PropTypes.shape({}).isRequired
}

Video.displayName = 'Video'

export default Video
