import { Children, isValidElement, useEffect, useMemo, useRef } from 'react'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import SwiperCore, { Keyboard, Lazy, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useBreakpoints } from '@hmn/rtl-web-core/hooks'

import AdSlot from '../../../Ad/NetSlot.component'
import { HtmlContent } from '../../../HtmlContent'
import { ChevronLeftIcon, Icon } from '../../../Icon'
import { Title } from '../../../Title'
import styles from './Content.style'

SwiperCore.use([Navigation, Keyboard, Lazy, Pagination])

const ContentStyled = styled.div(props => ({ ...styles(props) }))

function Content({
    className,
    activeIndex,
    onChange,
    subtitle,
    title,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    titleTagComponent: TitleTag,
    lead,
    children,
    categoryColor,
    handleFirstSlideClick,
    handleLastSlideClick,
    adSlotConfig,
    ...rest
}) {
    const swiperComponent = useRef()
    const itemElements = useMemo(() => Children.toArray(children).filter(isValidElement), [children])
    const uid = useUIDSeed()
    const [isDesktop] = useBreakpoints('md')
    const currentIndexRef = useRef(0)

    const params = {
        slidesPerView: 1,
        initialSlide: activeIndex,
        autoHeight: true,
        simulateTouch: false
    }

    useEffect(() => {
        // swiperjs needs to be updated on each prop/params change
        // that includes initial component mount because of dynamic props depending on variant and/or resolution
        const swiper = swiperComponent?.current?.swiper
        if (swiper) {
            swiper.params = {
                ...swiper.params,
                ...params
            }
            // @NOTE: empty area under image on gallery reload issue solution hack
            setTimeout(() => {
                swiper.update()
            }, 100)
        }
    }, [swiperComponent, itemElements])

    useEffect(() => {
        swiperComponent?.current?.swiper?.slideTo(activeIndex)
    }, [activeIndex])

    const handleSlideChange = swiper => {
        // @NOTE: hack for infinite galleries
        setTimeout(() => {
            currentIndexRef.current = swiper.activeIndex
            onChange(swiper.activeIndex)
        }, 200)
    }

    const handleKeyPress = (swiper, e) => {
        if (e === 37 && activeIndex === 0) {
            // left arrow key pressed
            handleFirstSlideClick()
        }
        if (e === 39 && activeIndex + 1 === itemElements.length) {
            // right arrow key pressed
            handleLastSlideClick()
        }
    }

    const handleSliderFirstMove = swiper => {
        if (swiper.touches.startX > swiper.touches.currentX && activeIndex + 1 === itemElements.length) {
            // swipe to the right
            handleLastSlideClick()
        } else if (swiper.touches.startX < swiper.touches.currentX && activeIndex === 0) {
            // swipe to the left
            handleFirstSlideClick()
        }
    }

    const renderNavButton = (direction, clickHandler) => (
        <div
            onClick={clickHandler}
            onKeyDown={clickHandler}
            className={`swiperNav_${direction}`}
            disabled={false}
            role="button">
            <Icon rotate={direction === 'prev' ? 180 : 0} icon={ChevronLeftIcon} viewBox="0 0 64 64" size={64} />
        </div>
    )

    if (!itemElements.length) {
        return null
    }

    return (
        <ContentStyled className={className} {...rest}>
            <div className="galleryContentNet_head" id="gallery_content_head">
                <Title title={title} subtitle={subtitle} titleTagComponent="h1" categoryColor={categoryColor} />
            </div>

            <div className="galleryContentNet_wrapper" id="gallery_content_wrapper">
                <Swiper
                    ref={swiperComponent}
                    followFinger={false}
                    keyboard={{ enabled: true, onlyInViewport: false, pageUpDown: false }}
                    onSlideChange={handleSlideChange}
                    id="swiper_container"
                    speed={0}
                    onKeyPress={handleKeyPress}
                    onSliderFirstMove={handleSliderFirstMove}
                    navigation={{ prevEl: '.swiperNav_prev', nextEl: '.swiperNav_next' }}
                    pagination={{ el: '.swiper-pagination', dynamicBullets: true, enabled: !isDesktop }}
                    preloadImages={false}
                    lazy={{ loadPrevNext: true, loadPrevNextAmount: 3 }}
                    {...params}>
                    {itemElements.map((child, index) => {
                        const { item, id } = child?.props || {}
                        return (
                            <SwiperSlide
                                key={uid(id || item?.id || index)}
                                id={activeIndex === index ? 'swiper_slide_active' : 'swiper_slide'}
                                onClick={() => {
                                    if (swiperComponent?.current?.swiper?.isEnd) {
                                        handleLastSlideClick()
                                    } else {
                                        swiperComponent?.current?.swiper?.slideNext()
                                    }
                                }}>
                                {child}
                            </SwiperSlide>
                        )
                    })}
                    {renderNavButton('prev', e => {
                        e.preventDefault()
                        e.stopPropagation()
                        if (currentIndexRef.current === 0) {
                            handleFirstSlideClick()
                        }
                    })}
                    {renderNavButton('next', e => {
                        e.stopPropagation()
                        e.preventDefault()
                        if (currentIndexRef.current + 1 === itemElements.length) {
                            handleLastSlideClick()
                        }
                    })}
                    <div className="swiper-pagination" />
                    {adSlotConfig && (
                        <div className="galleryContentNet_ad">
                            <AdSlot {...adSlotConfig} />
                        </div>
                    )}
                </Swiper>
            </div>
            {lead && <HtmlContent data={lead} className="galleryContentNet_lead" id="gallery_content_lead" />}
        </ContentStyled>
    )
}

Content.propTypes = {
    className: PropTypes.string,
    activeIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    lead: PropTypes.string,
    categoryColor: PropTypes.string,
    handleFirstSlideClick: PropTypes.func,
    handleLastSlideClick: PropTypes.func,
    adSlotConfig: PropTypes.shape(AdSlot.propTypes)
}

Content.defaultProps = {
    className: undefined,
    activeIndex: 0,
    onChange: undefined,
    subtitle: undefined,
    title: undefined,
    titleTagComponent: 'h1',
    lead: undefined,
    categoryColor: undefined,
    handleFirstSlideClick: undefined,
    handleLastSlideClick: undefined,
    adSlotConfig: undefined
}

export default withErrorBoundary(Content, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[GalleryContent]: ', error, componentStack)
    }
})
