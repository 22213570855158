const idSets = {
    regionalWidget: ['6QBS3E', 'PFPPK5', 'GYN2ZC', 'H8PYBX', 'H93AXJ', 'DG8VCJ'],
    articleHigherWidget: {
        desktop: ['1be203ec7b', '849fc04cb4', '801a6c9dab', '33e1635bf8'],
        mobile: ['e537f1e51e', '47e9b8753c', 'e9efef9c0b', '3f3aededf3', '083c29b541', '281e1c4e49']
    },
    articleLowerWidget: {
        desktop: [],
        mobile: ['98fc460d88', '1a31ce82ba', 'f190363023', 'a689a4c3f1', '78be8894ae']
    },
    inarticleWidget: ['4c39bcb580'],
    endarticleWidget: ['349240e247', 'a01280d9d2', '38849dbead', '9fdfe16c71']
}

// added for new recirculation tests instead of AdNow
const newIdSets = {
    inarticleWidgets: ['dc015c9fcb', '20c6a36b78'],
    underArticle1: ['8761f5982f', '22981cdaef', '7270816d28'],
    underArticle2: ['2450eba56e', 'c68c8847b5', '72275a2a84', '05e65401b3'],
    underArticle3: ['fc598e669e', '1b7a7eeab3', 'a72cf408ac'],
    underArticle4: ['355bed83fa', '82966a5efd', 'e2bf106766']
}

const categoryMappedIdSets = {
    endarticleWidget: {
        default: idSets.endarticleWidget,
        danas: ['96833cd6e2', '02f580cd83', '6878380768', '3e24ecae3c'],
        sport: ['570297282d', '09cd44ab06', 'd7a524ff7e', '2456141356'],
        hot: ['546f89a40c', '505b727377', 'bde67d6010', '25668b2802'],
        magazin: ['122b8cc868', 'f607c657a4', '81220c3614', '6439ea26ee'],
        webcafe: ['d2ec269efa', '1ee9cdebc5', '5b2e705545', 'a30d9716de']
    },
    inarticleWidget: {
        default: idSets.inarticleWidget,
        danas: ['0489b0a4e6'],
        sport: ['fee24a077e'],
        hot: ['3e6375b511'],
        magazin: ['764ad21d16'],
        webcafe: ['9860809b5b']
    }
}

export { idSets, categoryMappedIdSets, newIdSets }
