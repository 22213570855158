const variants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta',
    GAMMA: 'gamma',
    DELTA: 'delta',
    EPSILON: 'epsilon',
    ZETA: 'zeta',
    IOTA: 'iota',
    INARTICLE_ALPHA: 'inarticle-alpha',
    INARTICLE_ALPHA_MOB: 'inarticle-alpha-mob',
    ENDARTICLE_ALPHA: 'endarticle-alpha',
    ENDARTICLE_ALPHA_MOB: 'endarticle-alpha-mob',

    // TESTS:
    INARTICLE_TEST: 'inarticle-test',
    INARTICLE_TEST_MOB: 'inarticle-test-mob',
    UNDERARTICLE_TEST_3_ARTICLES: 'underarticle-test-3a',
    UNDERARTICLE_TEST_4_ARTICLES: 'underarticle-test-4a'
})
const config = {
    [variants.ALPHA]: {
        areaXs: `"a" "b" "c" "d"`,
        areaMd: `"a a b" "a a c" "a a d"`,
        columnsXs: '1fr',
        columnsMd: '1fr 1fr 2fr',
        count: 4,
        config: [
            {
                variant: 'ALPHA',
                size: '1x5',
                image: 'CUSTOM_SQUARE',
                imageHeight: 350,
                imageWidth: 350
            },
            {
                variant: 'BETA',
                size: '1x6',
                image: 'CUSTOM_SQUARE',
                imageHeight: 100,
                imageWidth: 100
            }
        ]
    },
    [variants.INARTICLE_TEST]: {
        areaXs: `"a" "b"`,
        areaMd: `"a" "b"`,
        columnsXs: '1fr',
        columnsMd: '1fr',
        count: 2,
        config: [
            {
                variant: 'BETA',
                size: '2x3',
                image: 'CUSTOM_WIDE_SCREEN',
                imageHeight: 167,
                imageWidth: 167,
                useUrlColor: true
            }
        ]
    },
    [variants.UNDERARTICLE_TEST_3_ARTICLES]: {
        areaXs: `"a" "b" "c"`,
        areaMd: `"a b c"`,
        columnsXs: '1fr',
        columnsMd: '1fr 1fr 1fr',
        count: 3,
        // countXs: 3,
        config: [
            {
                variant: 'ALPHA',
                size: '1x3',
                image: 'CUSTOM_WIDE_SCREEN',
                imageHeight: 320,
                imageWidth: 320,
                useUrlColor: true
            }
        ]
    },
    [variants.UNDERARTICLE_TEST_4_ARTICLES]: {
        areaXs: `"a" "b" "c" "d"`,
        areaMd: `"a b c d"`,
        columnsXs: '1fr',
        columnsMd: '1fr 1fr 1fr 1fr',
        count: 4,
        config: [
            {
                variant: 'ALPHA',
                size: '1x3',
                image: 'CUSTOM_WIDE_SCREEN',
                imageHeight: 260,
                imageWidth: 420,
                useUrlColor: true
            }
        ]
    },
    [variants.BETA]: {
        areaXs: `"a" "b" "c" "d" "e"`,
        areaMd: `"a a b" "a a c" "a a d" "a a e"`,
        columnsXs: '1fr',
        columnsMd: '1fr 1fr 1fr',
        count: 5,
        config: [
            {
                variant: 'ALPHA',
                size: '1x5',
                image: 'CUSTOM_WIDE_SCREEN',
                imageHeight: 350,
                imageWidth: 350
            },
            {
                variant: 'BETA',
                size: '1x6',
                image: 'CUSTOM_WIDE_SCREEN',
                imageHeight: 100,
                imageWidth: 100
            }
        ]
    },
    [variants.GAMMA]: {
        areaXs: `"a" "b" "c" "d" "e"`,
        areaMd: `"a b c" "a b d" "a b e"`,
        columnsXs: '1fr',
        columnsMd: '1fr 1fr 1fr',
        count: 5,
        config: [
            {
                variant: 'ALPHA',
                size: '1x5',
                image: 'CUSTOM_WIDE_SCREEN',
                imageHeight: 350,
                imageWidth: 350
            },
            {
                variant: 'ALPHA',
                size: '1x5',
                image: 'CUSTOM_WIDE_SCREEN',
                imageHeight: 350,
                imageWidth: 350
            },
            {
                variant: 'ALPHA',
                size: '1x6',
                image: 'CUSTOM_WIDE_SCREEN',
                imageHeight: 150,
                imageWidth: 300
            },
            {
                variant: 'BETA',
                size: '1x6',
                image: 'CUSTOM_WIDE_SCREEN',
                imageHeight: 100,
                imageWidth: 100
            },
            {
                variant: 'BETA',
                size: '1x6',
                image: 'CUSTOM_WIDE_SCREEN',
                imageHeight: 100,
                imageWidth: 100
            }
        ]
    },
    [variants.DELTA]: {
        areaXs: `"a b" "c d" "e f"`,
        areaMd: `"a b c d e f"`,
        columnsXs: '1fr 1fr',
        columnsMd: '1fr 1fr 1fr 1fr 1fr 1fr',
        count: 6,
        config: [
            {
                variant: 'ALPHA',
                size: '1x6',
                image: 'CUSTOM_WIDE_SCREEN',
                imageHeight: 107,
                imageWidth: 167
            }
        ]
    },
    [variants.EPSILON]: {
        areaXs: `"a" "b" "c" "d" "e" "f" "g" "h"`,
        areaMd: `"a b c d e"`,
        columnsXs: '1fr',
        columnsMd: '1fr 1fr 1fr 1fr 1fr',
        count: 5,
        config: [
            {
                variant: 'ALPHA',
                size: '1x5',
                image: 'CUSTOM_WIDE_SCREEN',
                imageHeight: 160,
                imageWidth: 250
            }
        ]
    },
    [variants.ZETA]: {
        areaXs: `"a" "b" "c" "d"`,
        areaMd: `"a b c d"`,
        columnsXs: '1fr',
        columnsMd: '1fr 1fr 1fr 1fr',
        count: 4,
        config: [
            {
                variant: 'ALPHA',
                size: '1x5',
                image: 'CUSTOM_WIDE_SCREEN',
                imageHeight: 160,
                imageWidth: 250
            }
        ]
    },
    [variants.IOTA]: {
        areaXs: `"a" "b" "c" "d" "e" "f"`,
        areaMd: `"a b c d e f"`,
        columnsXs: '1fr',
        columnsMd: '1fr 1fr 1fr 1fr 1fr 1fr',
        count: 6,
        config: [
            {
                variant: 'BETA',
                size: '1x6',
                image: 'CUSTOM_WIDE_SCREEN',
                imageHeight: 100,
                imageWidth: 100
            }
        ]
    },
    [variants.INARTICLE_ALPHA]: {
        areaXs: `"a"`,
        areaMd: `"a"`,
        columnsXs: '1fr',
        columnsMd: '1fr',
        count: 1,
        config: [
            {
                variant: 'BETA',
                size: '2x3',
                image: 'CUSTOM_WIDE_SCREEN',
                imageHeight: 167,
                imageWidth: 167,
                useUrlColor: true
            }
        ]
    },
    [variants.ENDARTICLE_ALPHA]: {
        areaXs: `"a" "b" "c" "d"`,
        areaMd: `"a" "b" "c" "d"`,
        columnsXs: '1fr',
        columnsMd: '1fr',
        count: 4,
        config: [
            {
                variant: 'BETA',
                size: '2x3',
                image: 'CUSTOM_WIDE_SCREEN',
                imageHeight: 167,
                imageWidth: 167,
                useUrlColor: true
            }
        ]
    }
}
config[variants.INARTICLE_TEST_MOB] = { ...config[variants.INARTICLE_TEST], count: 1, areaXs: `"a"`, areaMd: `"a"` }
config[variants.INARTICLE_TEST_MOB].config = config[variants.INARTICLE_TEST_MOB].config.map(c => ({
    ...c,
    image: 'CUSTOM_SQUARE',
    imageHeight: 100,
    imageWidth: 100
}))

config[variants.ENDARTICLE_ALPHA_MOB] = { ...config[variants.ENDARTICLE_ALPHA] }
config[variants.ENDARTICLE_ALPHA_MOB].config = config[variants.ENDARTICLE_ALPHA_MOB].config.map(c => ({
    ...c,
    image: 'CUSTOM_WIDE_SCREEN',
    imageHeight: 100,
    imageWidth: 100
}))

export { config, variants }
