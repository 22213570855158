import React from 'react'
import { useUIDSeed } from 'react-uid'
import PropTypes from 'prop-types'

import { Column } from '@hmn/rtl-web-core/components/layout'

import { articleCardContentMarkerVariants } from '@hmn/rtl-net-ui/components/Article/components'
import ArticleCard from '@hmn/rtl-net-ui/components/Article/components/Card/Card.component'
import CardsBlock from '@hmn/rtl-net-ui/components/CardsBlock/CardsBlock.component'

const variants = Object.freeze({
    ALPHA: 'alpha', // regular box below article
    BETA: 'beta' // in sidebar
})

const config = {
    [variants.ALPHA]: {
        areaXs: `"a b c"`,
        columnsXs: '1fr 1fr 1fr',
        count: 3,
        config: [
            {
                variant: 'ALPHA',
                size: '1x3',
                image: 'CUSTOM_WIDE_SCREEN',
                imageHeight: 107,
                imageWidth: 167
            },
            {
                variant: 'ALPHA',
                size: '1x3',
                image: 'CUSTOM_WIDE_SCREEN',
                imageHeight: 107,
                imageWidth: 167
            },
            {
                variant: 'ALPHA',
                size: '1x3',
                image: 'CUSTOM_WIDE_SCREEN',
                imageHeight: 107,
                imageWidth: 167
            }
        ]
    },
    [variants.BETA]: {
        areaXs: `"a" "b" "c"`,
        columnsXs: '1fr',
        count: 3,
        config: [
            {
                variant: 'ALPHA',
                size: '1x4',
                image: 'CUSTOM_3x2',
                imageHeight: 260,
                imageWidth: 420
            },
            {
                variant: 'BETA',
                size: '1x6',
                image: 'CUSTOM_3X2',
                imageHeight: 107,
                imageWidth: 167
            },
            {
                variant: 'BETA',
                size: '1x6',
                image: 'CUSTOM_3X2',
                imageHeight: 107,
                imageWidth: 167
            }
        ]
    }
}
const articleCardContentMarkerVariant = type => {
    switch (type.toUpperCase()) {
        case 'ARTICLE':
            return articleCardContentMarkerVariants.ARTICLE
        case 'GALLERY':
            return articleCardContentMarkerVariants.GALLERY
        case 'VIDEO':
            return articleCardContentMarkerVariants.VIDEO
        default:
            return articleCardContentMarkerVariants.NONE
    }
}

const gtmDataJosIzRubrikeBelow = {
    eventCategory: 'Clanak',
    eventAction: 'Below Jos iz rubrike',
    eventLabel: 'Main'
}
function MoreFromTheColumnBox({ variant, items, slice = [0, 10] }) {
    const [sliceStart, sliceEnd] = slice
    const slicedItems = items?.slice?.(sliceStart, sliceEnd) || []

    const uid = useUIDSeed()

    if (slicedItems.length === 0) {
        return null
    }

    return (
        <Column variant="article.josIzRubrike" id="jos_iz_rubrike_wrapper">
            <CardsBlock id="jos_iz_rubrike" title="Još iz rubrike" {...config[variant]}>
                {slicedItems.map((item, index) => {
                    const isBeta = variant === variants.BETA
                    const isFirst = index === 0

                    return (
                        <ArticleCard
                            item={item}
                            key={uid(item?.id || index)}
                            contentMarkerVariant={articleCardContentMarkerVariant(item?.type)}
                            gtmData={gtmDataJosIzRubrikeBelow}
                            imageSizesMd={(isBeta && ((isFirst && 33) || 17)) || 17}
                            imageSizesSm={(isBeta && ((isFirst && 100) || '110px')) || 100}
                            imageWidth135={variant === variants.BETA ? index !== 0 : true}
                            titleTagComponent="h4"
                        />
                    )
                })}
            </CardsBlock>
        </Column>
    )
}

MoreFromTheColumnBox.propTypes = {
    variant: PropTypes.oneOf([...Object.values(variants)]),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            type: PropTypes.string
        })
    ).isRequired,
    slice: PropTypes.arrayOf(PropTypes.number)
}

MoreFromTheColumnBox.defaultProps = {
    variant: variants.ALPHA,
    slice: [0, 10]
}

export { variants }
export default MoreFromTheColumnBox
