import { memo, useMemo } from 'react'
import { InView } from 'react-intersection-observer'
import { useUIDSeed } from 'react-uid'
import PropTypes from 'prop-types'

import { useEnvironment } from '../../hooks'
import useDeviceType from '../../hooks/layout/useDeviceType'
import Debug from '../Debug/Debug.component.tsx'
import { withErrorBoundary } from '../ErrorBoundary/ErrorBoundary.component'
import { sendThecrossnetClick, sendThecrossnetView, useThecrossnetData } from './ExchangeWidget.hooks'

const reduceArea = (area, count) => {
    // area:
    // `"a" "b" "c" "d" "e"`
    // `"a a b" "a a c" "a a d" "a a e"`
    const rowsParsed = area.match(/"([^"]+)"/g).map(row => row.replace(/"/g, '').split(' ').filter(Boolean))
    // map out all the tokens in the area
    const tokens = rowsParsed.reduce((acc, rowTokens) => {
        rowTokens.forEach(token => {
            acc[token] = acc[token] ? acc[token] + 1 : 1
        })
        return acc
    }, {})
    const tokenCount = Object.keys(tokens).length
    // if we have less tokens than count, return the area as is
    if (tokenCount <= count) {
        return area
    }
    // if we have more tokens than count, remove the extra tokens
    const keepTokens = Object.keys(tokens).sort().slice(0, count)
    const newArea = rowsParsed
        // filter only rows with tokens we're keeping
        .filter(rowTokens => keepTokens.some(token => rowTokens.includes(token)))
        // map out the rows to the required format
        .map(rowTokens => `"${rowTokens.filter(token => keepTokens.includes(token)).join(' ')}"`)
        .join(' ')
    return newArea
}

export function ExchangeWidget({
    className,
    exchangeKeys,
    title,
    titleSizeVariant,
    titleTagComponent,
    useItemSubtitle,
    deviceProp,

    areaXs: areaXsProp,
    areaMd: areaMdProp,
    columnsXs,
    columnsMd,
    count,
    config,

    cardIdPrefix,
    ArticleCardComponent,
    CardsBlockComponent,
    inArticle,
    disableDedupe
}) {
    const [deviceType] = useDeviceType()

    const uid = useUIDSeed()
    const { thirdPartyEnabled } = useEnvironment()

    const isEnabled = useMemo(
        () => thirdPartyEnabled && deviceType && (deviceProp === deviceType || !deviceProp),
        [thirdPartyEnabled, deviceProp, deviceType]
    )

    const { data } = useThecrossnetData({
        widgetIds: exchangeKeys,
        enabled: isEnabled && exchangeKeys?.length > 0,
        count,
        disableDedupe
    })
    const { areaXs, areaMd } = useMemo(() => {
        if (!data?.length) {
            return { areaXs: areaXsProp, areaMd: areaMdProp }
        }
        const l = data.length
        return {
            areaXs: reduceArea(areaXsProp, l),
            areaMd: areaMdProp
        }
    }, [areaXsProp, areaMdProp, data?.length])
    if (!isEnabled || !data?.length) {
        return null
    }
    return (
        <CardsBlockComponent
            className={className}
            title={title}
            titleSizeVariant={titleSizeVariant || '1x1'}
            areaXs={areaXs}
            areaMd={areaMd}
            columnsXs={columnsXs}
            columnsMd={columnsMd}
            count={Math.min(data.length, count)}
            config={config}
            separator={inArticle}
            noBorder={inArticle}
            titleTagComponent={titleTagComponent}
            useItemSubtitle={useItemSubtitle}>
            {data?.map((item, index) => (
                <ArticleCardComponent
                    key={uid(item?.id || index)}
                    cardId={cardIdPrefix ? `${cardIdPrefix}_${index}` : undefined}
                    item={item}
                    href={item.href}
                    hrefAs={item.href}
                    isExternal
                    // embedded={title !== 'Regionalni portali'}
                    urlImageSrc={item.image}
                    contentMarkerVariant="ARTICLE"
                    className={`ExchangeWidget_${item.widgetId}_item_${item.id}`}
                    titleTagComponent="h4"
                    onClick={() => {
                        sendThecrossnetClick(item)
                    }}>
                    <InView
                        delay={600}
                        threshold={0.7}
                        triggerOnce
                        initialInView={false}
                        onChange={inView => {
                            if (inView) {
                                sendThecrossnetView(item)
                            }
                        }}
                    />
                </ArticleCardComponent>
            ))}
        </CardsBlockComponent>
    )
}
export const exchangeWidgetPropTypes = {
    title: PropTypes.string,
    titleSizeVariant: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    className: PropTypes.string,
    domain: PropTypes.string,
    itemId: PropTypes.string,
    cardIdPrefix: PropTypes.string,
    exchangeKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    areaXs: PropTypes.string,
    areaMd: PropTypes.string,
    columnsXs: PropTypes.string,
    columnsMd: PropTypes.string,
    config: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)]),
    count: PropTypes.number,
    countXs: PropTypes.number,
    blockColor: PropTypes.string,
    useItemSubtitle: PropTypes.bool,
    deviceProp: PropTypes.string,
    inArticle: PropTypes.bool,
    disableDedupe: PropTypes.bool
}

export const exchangeWidgetDefaultProps = {
    title: undefined,
    titleSizeVariant: '1x1',
    titleTagComponent: 'h1',
    className: undefined,
    domain: 'www.net.hr',
    cardIdPrefix: undefined,
    itemId: undefined,
    exchangeKey: undefined,
    areaXs: `"a b" "c d" "e f"`,
    areaMd: `"a b c d e f"`,
    columnsXs: '1fr 1fr',
    columnsMd: '1fr 1fr 1fr 1fr 1fr 1fr',
    config: undefined,
    count: 6,
    countXs: 6,
    blockColor: undefined,
    useItemSubtitle: true,
    deviceProp: undefined,
    inArticle: false,
    disableDedupe: false
}

ExchangeWidget.propTypes = {
    ...exchangeWidgetPropTypes,
    exchangeKeys: PropTypes.arrayOf(PropTypes.string),
    ArticleCardComponent: PropTypes.elementType,
    CardsBlockComponent: PropTypes.elementType
}

ExchangeWidget.defaultProps = {
    ...exchangeWidgetDefaultProps,
    ArticleCardComponent: Debug,
    CardsBlockComponent: Debug
}
export default withErrorBoundary(memo(ExchangeWidget), {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ExchangeWidget]: ', error, componentStack)
    }
})
