import React, { useMemo } from 'react'
import { useUIDSeed } from 'react-uid'
import PropTypes from 'prop-types'

// import { AntiClsFrame } from '@hmn/rtl-web-core/components/AntiClsFrame'
import { Column, Row } from '@hmn/rtl-web-core/components/layout'
import { Media } from '@hmn/rtl-web-core/components/Media'
// import { RenderOnDay } from '@hmn/rtl-web-core/components/RenderOnDay'
import SlidingWrapper from '@hmn/rtl-web-core/components/SlidingWrapper/SlidingWrapper.component'
import { modifyInsertElementsForShorterArticles } from '@hmn/rtl-web-core/helpers/article/measureArticleLengthScore'
import { useCrossDomainCommunication as usePreview, useInArticleAds } from '@hmn/rtl-web-core/hooks'
import useEntityTypeDispatch from '@hmn/rtl-web-core/hooks/entity/useEntityTypeDispatch'
import { IsolatedEntityThirdParties } from '@hmn/rtl-web-core/hooks/IsolatedEntityThirdParties'

import { insertAds as articleInsertAds, staticAds } from '@hmn/rtl-net-ui/components/Ad/config/article'
import AdSlot from '@hmn/rtl-net-ui/components/Ad/NetSlot.component'
import AdNow from '@hmn/rtl-net-ui/components/AdNow/AdNow.component'
import {
    ArticleCard,
    articleCardContentMarkerVariant,
    ArticleContent,
    ArticleCtaBlock,
    ArticleMeta
} from '@hmn/rtl-net-ui/components/Article/components'
import { CardsBlock } from '@hmn/rtl-net-ui/components/CardsBlock'
import { userGeneratedContentVariants } from '@hmn/rtl-net-ui/components/Cta/components'
import Newsletter from '@hmn/rtl-net-ui/components/Cta/components/Newsletter/Newsletter.net.component'
// eslint-disable-next-line max-len
import UserGeneratedContent from '@hmn/rtl-net-ui/components/Cta/components/UserGeneratedContent/UserGeneratedContent.net.component'
import { exchangeWidgetVariants, widgetIdSets } from '@hmn/rtl-net-ui/components/ExchangeWidget'
import ExchangeWidget from '@hmn/rtl-net-ui/components/ExchangeWidget/ExchangeWidget.component'
import {
    DynamicListalicaProizvoda
    // listalicaProizvodaSourceVariants
} from '@hmn/rtl-net-ui/components/ListalicaProizvoda'
import { TrackingCode } from '@hmn/rtl-net-ui/components/TrackingCode'

import { useAdmiralInsert } from '../../hooks/useAdmiralInsert'
import { BaseLayout } from '../../layouts'
import { Seo } from '..'
import CombinedArticleHead from '../CombinedArticleHead'
import { SelectedArticlesBox } from '../SelectedArticlesBox'
import { YouMightBeInterestedBox } from '../YouMightBeInterestedBox'

const { billboard1, halfpage1, halfpage2, outOfPage1, outOfPage2, outOfPage3, interstitial } = staticAds
const halfpageSlidingProps = {
    offsetTop: 80,
    offsetBottom: 50,
    contentWidth: '100%',
    minimumFrameHeight: 600
}
function ColumnLayout({ ssrData, navigation }) {
    const uid = useUIDSeed()

    const { article, lengthScore, articleVideo, columnist, columnistArticles } = ssrData
    const { id } = article

    useEntityTypeDispatch('article')

    const { data: articleWithPreview } = usePreview(article, { type: 'preview', source: '@CMS' })

    const articleTags = useMemo(() => articleWithPreview?.tags || [], [articleWithPreview?.tags])
    const productGallery = articleWithPreview?.productGalleries?.[0]

    const isShort = lengthScore.lengthCategory === 'short'
    const isLong = lengthScore.lengthCategory === 'long'
    const insertAdsConfig = useMemo(
        () =>
            [articleInsertAds[0], modifyInsertElementsForShorterArticles(articleInsertAds[1], lengthScore)].filter(
                Boolean
            ),
        [lengthScore]
    )

    const insertAds = useInArticleAds({
        insertAdsConfig,
        AdSlotComponent: AdSlot,
        maxAdWidth: '700px',
        title: 'Tekst se nastavlja ispod oglasa'
    })
    const insertAdmiral = useAdmiralInsert(true)
    const insertElements = useMemo(() => [...insertAds, insertAdmiral].filter(Boolean), [insertAds, insertAdmiral])

    const moreFromTheColumnist1 = columnistArticles?.slice(0, 3)
    const moreFromTheColumnist2 = columnistArticles?.slice(3, 6)

    return (
        <BaseLayout navigationData={navigation}>
            <IsolatedEntityThirdParties
                entity={articleWithPreview}
                articleType="article"
                entityType="article"
                id={id}
                defaultGemiusId={process.env.NEXT_PUBLIC_GEMIUS_ID_NET}
                appRoot={process.env.NEXT_PUBLIC_APP_ROOT_NET}
                appName="net.hr"
                ampGtmId={process.env.NEXT_PUBLIC_AMP_GTM_NET}
            />
            <TrackingCode code={article?.extended_attributes?.tracking_code} />
            <Seo mainEntity item={articleWithPreview} />
            <Row variant="article.main">
                <Row variant="article.head">
                    <CombinedArticleHead
                        article={articleWithPreview}
                        articleVideo={articleVideo}
                        columnist={columnist}
                        isColumn
                    />

                    {/* ========= HEADER SIDEBAR ====== */}

                    <Column variant="article.head.aside">
                        <Media className="Sidebar_aside" greaterThan="sm" scaleDown>
                            <AdSlot
                                {...halfpage1}
                                desktopOnly
                                slidingProps={{
                                    ...(halfpage1.slidingProps || {}),
                                    offsetBottom: 120
                                }}
                            />
                        </Media>
                    </Column>
                </Row>

                <Media greaterThan="sm" scaleDown>
                    <Row variant="article.ads.billboard">
                        <AdSlot {...billboard1} desktopOnly />
                    </Row>
                </Media>
                {!isShort && lengthScore.score > 3600 && (
                    <Media lessThan="md" scaleDown>
                        <Row variant="article.ads.billboard">
                            <AdSlot {...billboard1} mobileOnly maxAdHeight={!isLong ? 400 : undefined} />
                        </Row>
                    </Media>
                )}
                <Row variant="article.mainContent">
                    <Row variant="article.mainContent.body">
                        <Column variant="article.content">
                            {/* @NOTE: className is used for upscore purposes */}
                            <ArticleContent
                                className="article-body"
                                content={articleWithPreview?.body}
                                insertElements={insertElements}
                            />
                        </Column>
                        {articleWithPreview?.extended_attributes?.cta_title &&
                        articleWithPreview?.extended_attributes?.cta_url ? (
                            <Column variant="article.cta">
                                <ArticleCtaBlock
                                    content={articleWithPreview?.extended_attributes?.cta_title}
                                    title="Saznaj više o temi"
                                    href={articleWithPreview?.extended_attributes?.cta_url}
                                    buttonTitle={articleWithPreview?.extended_attributes?.cta_button_label}
                                />
                            </Column>
                        ) : null}
                        <Column variant="article.meta">
                            <ArticleMeta tags={articleTags} />
                        </Column>
                        <Column variant="article.mightBeInterested">
                            <YouMightBeInterestedBox excludeId={articleWithPreview.id} />
                        </Column>
                    </Row>

                    {/* ========= CONTENT SIDEBAR ====== */}

                    <Row variant="article.mainContent.aside">
                        <Media greaterThan="sm" scaleDown className="Sidebar_aside">
                            <AdSlot {...halfpage2} />
                            {moreFromTheColumnist1?.length ? (
                                <Column variant="article.ads.floating">
                                    <SlidingWrapper renabled {...halfpageSlidingProps}>
                                        <CardsBlock
                                            title="Još iz rubrike"
                                            areaXs={`"a" "b" "c"`}
                                            columnsXs="1fr"
                                            count={3}
                                            config={[
                                                {
                                                    variant: 'ALPHA',
                                                    size: '1x4',
                                                    image: 'CUSTOM_3x2',
                                                    imageHeight: 260,
                                                    imageWidth: 420
                                                },
                                                {
                                                    variant: 'BETA',
                                                    size: '1x6',
                                                    image: 'CUSTOM_3X2',
                                                    imageHeight: 107,
                                                    imageWidth: 167
                                                },
                                                {
                                                    variant: 'BETA',
                                                    size: '1x6',
                                                    image: 'CUSTOM_3X2',
                                                    imageHeight: 107,
                                                    imageWidth: 167
                                                }
                                            ]}>
                                            {moreFromTheColumnist1?.map((item, index) => (
                                                <ArticleCard
                                                    item={item}
                                                    key={uid(item?.id || index)}
                                                    contentMarkerVariant={articleCardContentMarkerVariant(item?.type)}
                                                />
                                            ))}
                                        </CardsBlock>
                                    </SlidingWrapper>
                                </Column>
                            ) : null}
                        </Media>
                    </Row>
                </Row>
                {productGallery && (
                    <Column variant="article.listalica">
                        <DynamicListalicaProizvoda
                            title={productGallery?.title}
                            items={productGallery?.extended_attributes?.product_galleries_products}
                            showLogo={!productGallery?.extended_attributes?.product_galleries_show_title}
                            logo={productGallery?.image}
                            titleUrl={productGallery?.extended_attributes?.product_galleries_url}
                        />
                    </Column>
                )}
                {/* @TODO: remove listalice after required dates  */}
                {/* <RenderOnDay days={['wednesday', 'thursday']} years={['2023']}>
                    <AntiClsFrame minHeightXs="375px" minHeightMd="410px">
                        <DynamicListalicaProizvoda sourceVariant={listalicaProizvodaSourceVariants.T_HT} />
                    </AntiClsFrame>
                </RenderOnDay> */}
                {articleWithPreview?.extended_attributes?.display_third_party_content && (
                    <Column variant="article.linker">
                        <AdNow />
                    </Column>
                )}
                <Media lessThan="md" scaleDown>
                    <Column variant="article.qpq" className="end-article column">
                        <ExchangeWidget
                            cardIdPrefix="end_article"
                            itemId={id}
                            exchangeKey={widgetIdSets?.articleHigherWidget?.mobile}
                            // title="Iz drugih medija new"
                            titleSizeVariant="1x1"
                            variant={exchangeWidgetVariants.IOTA}
                        />
                    </Column>

                    {/* <Column variant="article.listalica">
                        <DynamicListalicaProizvoda sourceVariant={listalicaProizvodaSourceVariants.NET_WEBSHOP} />
                    </Column> */}
                </Media>
                {moreFromTheColumnist1?.length > 0 && (
                    <Row variant="article.moreArticles">
                        {moreFromTheColumnist1?.length > 0 && (
                            <Column variant="article.josIzRubrike">
                                <CardsBlock
                                    title="Još iz rubrike"
                                    areaXs={`"a b c"`}
                                    columnsXs="1fr 1fr 1fr"
                                    count={3}
                                    config={[
                                        // {
                                        //     variant: 'ALPHA',
                                        //     size: '1x4',
                                        //     image: 'CUSTOM_3x2',
                                        //     imageHeight: 260,
                                        //     imageWidth: 420
                                        // },
                                        {
                                            variant: 'BETA',
                                            size: '1x6',
                                            image: 'CUSTOM_3X2',
                                            imageHeight: 107,
                                            imageWidth: 167
                                        },
                                        {
                                            variant: 'BETA',
                                            size: '1x6',
                                            image: 'CUSTOM_3X2',
                                            imageHeight: 107,
                                            imageWidth: 167
                                        },
                                        {
                                            variant: 'BETA',
                                            size: '1x6',
                                            image: 'CUSTOM_3X2',
                                            imageHeight: 107,
                                            imageWidth: 167
                                        }
                                    ]}>
                                    {moreFromTheColumnist2?.map((item, index) => (
                                        <ArticleCard
                                            item={item}
                                            key={uid(item?.id || index)}
                                            contentMarkerVariant={articleCardContentMarkerVariant(item?.type)}
                                        />
                                    ))}
                                </CardsBlock>
                            </Column>
                        )}
                    </Row>
                )}
                <Media greaterThan="sm" scaleDown>
                    <Row variant="article.facebook_ugc">
                        <Column variant="article.ugc">
                            <UserGeneratedContent hasTitleLineBreak variant={userGeneratedContentVariants.BETA} />
                        </Column>
                    </Row>
                    {/* <Column variant="article.listalica">
                        <DynamicListalicaProizvoda sourceVariant={listalicaProizvodaSourceVariants.NET_WEBSHOP} />
                    </Column> */}
                </Media>
                <Media lessThan="md" scaleDown>
                    <Column variant="article.regional">
                        <ExchangeWidget
                            cardIdPrefix="regional_mobile"
                            itemId={id}
                            exchangeKey={widgetIdSets?.articleLowerWidget?.mobile}
                            // title="Iz drugih medija"
                            titleSizeVariant="1x1"
                            variant={exchangeWidgetVariants.EPSILON}
                        />
                    </Column>
                    <Column variant="article.ugc">
                        <UserGeneratedContent hasTitleLineBreak variant={userGeneratedContentVariants.BETA} />
                    </Column>
                </Media>
                <Column variant="article.newsletter">
                    <Newsletter />
                </Column>
                <Column variant="article.regional">
                    <ExchangeWidget
                        cardIdPrefix="regional"
                        itemId={id}
                        exchangeKey={widgetIdSets?.regionalWidget}
                        title="Regionalni portali"
                        titleSizeVariant="1x1"
                        variant={exchangeWidgetVariants.DELTA}
                    />
                </Column>

                <Column variant="article.izdvojeno">
                    <SelectedArticlesBox excludeId={articleWithPreview.id} />
                </Column>
            </Row>

            <Row variant="zeroDimensions">
                <AdSlot {...outOfPage1} />
                <AdSlot {...outOfPage2} />
                <AdSlot {...outOfPage3} />
                <AdSlot {...staticAds.anchor} />
                <AdSlot {...interstitial} />
            </Row>
        </BaseLayout>
    )
}

ColumnLayout.propTypes = {
    ssrData: PropTypes.shape({
        lengthScore: PropTypes.shape({
            score: PropTypes.number,
            lengthCategory: PropTypes.oneOf(['short', 'medium', 'long'])
        }),
        columnist: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]),
        article: PropTypes.shape({
            id: PropTypes.string,
            extended_attributes: PropTypes.shape({
                tracking_code: PropTypes.string
            })
        }),
        columnistArticles: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]),
        articleVideo: PropTypes.shape({})
    }),
    navigation: PropTypes.shape({}).isRequired
}

ColumnLayout.defaultProps = {
    ssrData: undefined
}

ColumnLayout.displayName = 'Column'

export default ColumnLayout
