import { useMemo } from 'react'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import { useEnvironment } from '../../hooks'
import { parseRender } from './RenderOnDay.helpers'

const RenderOnDayDebug = dynamic(() => import('./RenderOnDayDebug.wrapper').then(mod => mod.default), {
    ssr: false
})
// @NOTE: not using week as attribute since it would require extending dayjs
// it's not required, it's left commented as example of how to extend fruther checks (eg, hour, minute, etc)
// @IDEA: implement checkFunction callback for render,
//        it returns {render:boolean, override:boolean} where boolean signals if component should use other checks
function RenderOnDay({ days, years, months, matchAny: useBooleanOr, children }) {
    const { isDev } = useEnvironment()
    const { render, ...parsedTimeSettings } = useMemo(
        () => parseRender({ days, months, years, useBooleanOr }),
        [days, years, months, useBooleanOr]
    )

    if (isDev) {
        return (
            <RenderOnDayDebug matchAny={useBooleanOr} render={render} {...parsedTimeSettings}>
                {children}
            </RenderOnDayDebug>
        )
    }
    if (!render) {
        return null
    }

    return children
}

const dateInputPropType = PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]))
])

RenderOnDay.propTypes = {
    days: dateInputPropType,
    years: dateInputPropType,
    months: dateInputPropType,
    matchAny: PropTypes.bool
}
RenderOnDay.defaultProps = {
    days: undefined,
    years: undefined,
    months: undefined,
    matchAny: false
}

export default RenderOnDay
