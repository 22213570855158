import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useUIDSeed } from 'react-uid'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import Link from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import SwiperCore, { Keyboard, Lazy, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { dotmetricsIDs } from '@hmn/rtl-web-core/components/analytics/Dotmetrics/Dotmetrics.component'
import { useAdCallbacks } from '@hmn/rtl-web-core/context/ads/callback.context'
import {
    useClickedImageIndexDispatch,
    useGalleryIdDispatch,
    useModalGalleryState
} from '@hmn/rtl-web-core/context/articleModalGallery'
import { useData, useGTM } from '@hmn/rtl-web-core/hooks'
import useDeviceType from '@hmn/rtl-web-core/hooks/layout/useDeviceType'
import { returnEmptyOn4xx } from '@hmn/rtl-web-core/next/data-fetching/handle-errors'

import { staticAds } from '../Ad/config/article'
import AdSlot from '../Ad/NetSlot.component'
import { AfterRenderCycle } from '../AfterRenderCycle'
import { CloseIcon, Icon, NetLogoColorIcon } from '../Icon'
import { Image, imageRatioVariants } from '../Image'
import { SocialShare } from '../Social/components'
import styles from './ModalGallery.style'

const ModalStyled = styled.div(props => ({ ...styles(props) }))

const gtmData = {
    eventCategory: 'Clanak',
    eventAction: 'Open Modal Gallery',
    eventLabel: 'Main'
}

// INFO: aspect ratio calculation, needed for responsive swiper container and images
function aspectRatio(swiperRef) {
    const { width, height } = swiperRef.current
    const swiperAspectRatio = width / height

    if (swiperRef.current?.slides) {
        const slide = swiperRef.current?.slides[swiperRef.current?.activeIndex]
        const slideAspectRatio = +(slide?.dataset?.slideAspect ?? 1)

        const ratio = swiperAspectRatio > slideAspectRatio ? 'is_portrait' : 'is_landscape'
        slide.dataset.finalAspect = ratio
    }
}

function mergeGalleryImages(galleryData) {
    const featuredImageId = galleryData?.image
    const imageIds = galleryData?.extended_attributes?.gallery_images_enriched || []
    return [featuredImageId, ...imageIds].filter(Boolean)
}

function processDescription(descriptionElement) {
    if (descriptionElement && typeof descriptionElement.getClientRects === 'function') {
        const innerElement = descriptionElement.querySelector('i')
        const messageLines = innerElement ? innerElement.getClientRects().length : 0

        if (messageLines > 2) {
            descriptionElement.classList.add('has_hiddenLines')
        } else {
            descriptionElement.classList.remove('has_hiddenLines')
        }
    }
}

SwiperCore.use([Keyboard, Lazy, Navigation, Pagination])

function ModalGallery({ title, subtitle, className, categoryColor }) {
    const uid = useUIDSeed()

    const slideChangesCountRef = useRef(0)
    const swiperRef = useRef(null)
    const descriptionRefs = useRef([])

    const router = useRouter()
    const [deviceType] = useDeviceType()
    const { displayAd } = useAdCallbacks()
    const { sendDataToGTM } = useGTM()
    const { clickedImageIndex, galleryId } = useModalGalleryState()
    const setClickedImageIndex = useClickedImageIndexDispatch()
    const setGalleryId = useGalleryIdDispatch()

    const isOpen = galleryId && clickedImageIndex !== null

    const goToNextSlide = () => {
        swiperRef.current?.slideNext()
    }
    const closeGalleryX = () => {
        router.back()
    }

    const toggleDescription = index => {
        const descElement = descriptionRefs.current[index]
        if (descElement) {
            descElement.classList.toggle('is_open')
        }
    }

    // Gallery Data Fetching
    const { data: galleryData } = useData({
        enabled: !!galleryId,
        resource: `entity/${galleryId}`,
        options: { preview: true },
        errorHandlers: returnEmptyOn4xx
    })

    // Process and filter gallery images
    const allImages = useMemo(() => mergeGalleryImages(galleryData), [galleryData])

    // Manage the display of the modal mobile and desktop ads
    const displayModalAd = useCallback(() => {
        if (deviceType) {
            displayAd(staticAds.galleryModal.position)
        }
    }, [displayAd, deviceType])

    // Measure and calculate the relative aspect ratio of the swiper container and the images
    const measureAspectRatio = useCallback(() => {
        if (swiperRef.current) {
            aspectRatio(swiperRef)
        }
    }, [])

    const onSlideChange = useCallback(() => {
        // Close all descriptions
        descriptionRefs.current.forEach(desc => {
            if (desc.classList.contains('is_open')) {
                desc.classList.remove('is_open')
            }
        })

        measureAspectRatio()

        // Get and process the current slide's description element
        const activeIndex = swiperRef.current?.activeIndex
        const currentDescriptionElement = descriptionRefs.current[activeIndex]
        processDescription(currentDescriptionElement)

        // Increment the slide change count
        slideChangesCountRef.current += 1

        // Display ad every 3 slide changes
        if (slideChangesCountRef.current % 3 === 0) {
            displayModalAd()
        }

        // Send Analytics data
        sendDataToGTM({
            event: 'VirtualPageView',
            eventCategory: 'Clanak',
            eventAction: `Modal Gallery Slide ${slideChangesCountRef.current}`,
            eventLabel: 'Main'
        })

        const gallerySection = galleryData?.taxons?.sitemap?.[0]?.path?.[0]?.title.toLowerCase()
        const { id: dotmetricsID } = dotmetricsIDs[`${gallerySection}Gallery`] || dotmetricsIDs.gallery
        // console.log('dm virtual from ModalGallery component', gallerySection, dotmetricsID)

        window?.dm?.AjaxEvent('pageview', null, dotmetricsID)
        // window?.pp_gemius_hit(galleryData?.sitemap?.node?.gemiusId || process.env.NEXT_PUBLIC_GEMIUS_ID_NET)

        window.marfeel = window.marfeel || { cmd: [] }
        window.marfeel.cmd.push([
            'compass',
            function compassFunction(compass) {
                compass.trackNewPage({ rs: 'Modal Gallery' })
            }
        ])

        const upScorePayload = {
            domain: 'net.hr',
            object_id: galleryData?.id,
            section: galleryData?.taxons?.sitemap?.[0]?.path?.[0]?.title || 'other',
            taxonomy: galleryData?.taxons?.sitemap?.[0]?.path?.[1]?.title || 'other',
            url: window?.location?.href,
            object_type: 'galleryData',
            sequence: slideChangesCountRef.current + 1,
            author: galleryData?.creator?.display_name || galleryData?.taxons?.authors?.[0]?.title || 'net.hr',
            pubdate: galleryData?.extended_attributes?.published_at
                ? new Date(galleryData.extended_attributes.published_at).toISOString()
                : null
        }
        if (window?.upScore) {
            window.upScore({ data: upScorePayload })
        }
    }, [displayModalAd, sendDataToGTM, measureAspectRatio, galleryData?.sitemap?.node?.gemiusId])

    // close the gallery on 'X' button and escape key
    const closeGallery = useCallback(() => {
        setClickedImageIndex(null)
        setGalleryId(null)
    }, [setClickedImageIndex, setGalleryId])

    // close the gallery on escape key
    useEffect(() => {
        const handleKeyDown = event => {
            if (event.key === 'Escape') {
                router.back()
            }
        }

        if (isOpen) {
            window.history.pushState(null, '', router.asPath)
        }

        document.addEventListener('keydown', handleKeyDown)
        window.addEventListener('popstate', closeGallery)

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
            window.removeEventListener('popstate', closeGallery)
        }
    }, [closeGallery, isOpen])

    // prevent body scroll when the modal is open
    useEffect(() => {
        if (isOpen) {
            // Calculate the scrollbar width
            const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
            const originalBodyStyle = document.body.getAttribute('style') || ''

            document.body.style.overflow = 'hidden'
            document.body.style.paddingRight = `${scrollbarWidth}px`

            return () => {
                document.body.setAttribute('style', originalBodyStyle)
            }
        }
        document.body.style.overflow = ''
        document.body.style.paddingRight = ''
        return () => {
            const originalBodyStyle = document.body.getAttribute('style') || ''
            document.body.setAttribute('style', originalBodyStyle)
        }
    }, [isOpen, displayAd])

    if (!isOpen || allImages.length === 0) {
        return null
    }

    // send Analytics data
    sendDataToGTM(gtmData)

    return (
        <ModalStyled open={isOpen} className={className} categoryColor={categoryColor} role="presentation">
            <div className="modal__inner gallery" role="none presentation">
                <header className="gallery__header">
                    <span className="gallery__logo">
                        <Link href="/">
                            <NetLogoColorIcon className="gallery__logo-img" viewBox="0 0 100 24" width="120" />
                        </Link>
                    </span>

                    <div className="gallery__header_share">
                        <SocialShare />
                    </div>

                    <button className="gallery__close" type="button" onClick={() => closeGalleryX()}>
                        <Icon icon={CloseIcon} />
                    </button>
                </header>

                <main className="gallery__main">
                    <aside className="gallery__share">
                        <SocialShare />
                    </aside>

                    <section className="gallery__swiper">
                        <h2 className="title">
                            <span className="title_subtitle">{subtitle} / </span>
                            {title}
                        </h2>

                        <div className="swiper__container">
                            <Swiper
                                initialSlide={clickedImageIndex}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation
                                keyboard={{ enabled: true }}
                                pagination={{ type: 'fraction', clickable: true }}
                                onSlideChange={onSlideChange}
                                onSwiper={swiper => {
                                    swiperRef.current = swiper
                                    measureAspectRatio()

                                    // Log the initial slide's description
                                    const initialDescription = descriptionRefs.current[swiper.activeIndex]
                                    processDescription(initialDescription)
                                }}>
                                {allImages.map((image, index) => (
                                    <SwiperSlide
                                        key={uid(image.id + index)}
                                        onClick={goToNextSlide}
                                        data-slide-aspect={image?.original_aspect_ratio}>
                                        <div className="gallery__slide">
                                            <Image
                                                className="gallery__image"
                                                alt={title}
                                                image={image}
                                                width={1200}
                                                sizesXl={90 * Math.min(image?.original_aspect_ratio || 1, 1)}
                                                sizesMd={Math.round(
                                                    66 * Math.min(image?.original_aspect_ratio || 1, 1)
                                                )}
                                                sizesSm={100}
                                                minSrcsetWidth={300}
                                                originalAspectRatio={image?.original_aspect_ratio}
                                                variation={imageRatioVariants.CUSTOM_ORIGINAL}
                                                hideAuthor
                                            />

                                            {/* TODO: remove logic from source and description <span>s */}
                                            {(image.author || image.source) && (
                                                <span className="gallery__slide_source">
                                                    {image.author ? `Foto: ${image.author}` : `Izvor: ${image.source}`}
                                                </span>
                                            )}
                                            {image?.description && (
                                                <span
                                                    className="gallery__slide_description"
                                                    ref={el => {
                                                        descriptionRefs.current[index] = el
                                                    }}
                                                    role="button"
                                                    tabIndex="0"
                                                    onClick={e => {
                                                        if (
                                                            descriptionRefs.current[index]?.classList.contains(
                                                                'has_hiddenLines'
                                                            )
                                                        ) {
                                                            e.stopPropagation()
                                                            toggleDescription(index)
                                                        }
                                                    }}
                                                    onKeyDown={e => {
                                                        e.preventDefault()
                                                    }}>
                                                    <i>{image.description.replace(/<para>(.*?)<\/para>/gs, '$1')}</i>
                                                    <button
                                                        type="button"
                                                        className="show_more"
                                                        onClick={e => {
                                                            e.stopPropagation()
                                                            toggleDescription(index)
                                                        }}>
                                                        <strong className="open_msg">Pročitaj više</strong>
                                                        <strong className="close_msg">Zatvori</strong>
                                                    </button>
                                                </span>
                                            )}
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </section>

                    <aside className="gallery__marketing">
                        <AdSlot {...staticAds.galleryModal} />
                        {/* <AdSlot {...staticAds.inPicture} /> */}
                        <AfterRenderCycle callback={displayModalAd} />
                    </aside>
                </main>
            </div>
            <div className="modal__backdrop" />
        </ModalStyled>
    )
}

ModalGallery.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    category: PropTypes.string,
    categoryColor: PropTypes.string
}

ModalGallery.defaultProps = {
    className: undefined,
    title: undefined,
    subtitle: undefined,
    category: undefined,
    categoryColor: undefined
}

export default withTheme(ModalGallery)
