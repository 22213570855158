import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

// import { AntiClsFrame } from '@hmn/rtl-web-core/components/AntiClsFrame'
import { Column, Row } from '@hmn/rtl-web-core/components/layout'
import { Media } from '@hmn/rtl-web-core/components/Media'
// import { RenderOnDay } from '@hmn/rtl-web-core/components/RenderOnDay'
import { useCrossDomainCommunication as usePreview } from '@hmn/rtl-web-core/hooks'
import useEntityTypeDispatch from '@hmn/rtl-web-core/hooks/entity/useEntityTypeDispatch'
import { IsolatedEntityThirdParties } from '@hmn/rtl-web-core/hooks/IsolatedEntityThirdParties'

import AdNow from '@hmn/rtl-net-ui/components/AdNow/AdNow.component'
import { ArticleContent, ArticleMeta } from '@hmn/rtl-net-ui/components/Article/components'
import Newsletter from '@hmn/rtl-net-ui/components/Cta/components/Newsletter/Newsletter.net.component'
// eslint-disable-next-line max-len
import UserGeneratedContent from '@hmn/rtl-net-ui/components/Cta/components/UserGeneratedContent/UserGeneratedContent.net.component'
import { exchangeWidgetVariants, widgetIdSets } from '@hmn/rtl-net-ui/components/ExchangeWidget'
import ExchangeWidget from '@hmn/rtl-net-ui/components/ExchangeWidget/ExchangeWidget.component'
import { Zodiac as ZodiacCard, zodiacVariants } from '@hmn/rtl-net-ui/components/Fun/components'
import {
    DynamicListalicaProizvoda
    // listalicaProizvodaSourceVariants
} from '@hmn/rtl-net-ui/components/ListalicaProizvoda'
import { TrackingCode } from '@hmn/rtl-net-ui/components/TrackingCode'

import { BaseLayout } from '../../layouts'
import { Seo } from '..'
import CombinedArticleHead from '../CombinedArticleHead'
import { MoreFromTheColumnBox, moreFromTheColumnBoxVariants } from '../MoreFromTheColumnBox'
// import { ReadMoreWidget } from '../ReadMoreWidget'
import { SelectedArticlesBox } from '../SelectedArticlesBox'
import { YouMightBeInterestedBox } from '../YouMightBeInterestedBox'

function Zodiac({ ssrData, navigation }) {
    const { article, moreFromTheColumn, articleVideo, signList } = ssrData
    const { id } = article
    useEntityTypeDispatch('general')

    const { data: articleWithPreview } = usePreview(article, { type: 'preview', source: '@CMS' })

    /** ****************************RELATED STUFF************************************* */
    const articleTags = useMemo(() => articleWithPreview?.tags || [], [articleWithPreview?.tags])
    const productGallery = articleWithPreview?.productGalleries?.[0]

    return (
        <BaseLayout navigationData={navigation}>
            <IsolatedEntityThirdParties
                entity={articleWithPreview}
                articleType="Horoskop"
                entityType="zodiac"
                appName="net.hr"
                defaultGemiusId={process.env.NEXT_PUBLIC_GEMIUS_ID_NET}
                appRoot={process.env.NEXT_PUBLIC_APP_ROOT_NET}
                ampGtmId={process.env.NEXT_PUBLIC_AMP_GTM_ID_NET}
            />
            <TrackingCode code={articleWithPreview?.extended_attributes?.tracking_code} />
            <Seo mainEntity hasAmp item={articleWithPreview} />
            <Row variant="article.main">
                <Row variant="article.mainContent">
                    <Row variant="article.mainContent.body">
                        <CombinedArticleHead
                            article={articleWithPreview}
                            columnVariant="article.head"
                            isZodiac
                            articleVideo={articleVideo}
                        />
                        <Column variant="article.content">
                            {/* @NOTE: className is used for upscore purposes */}
                            <ArticleContent className="article-body" content={articleWithPreview?.body} />
                        </Column>

                        <Column variant="article.meta">
                            <ArticleMeta tags={articleTags} />
                        </Column>
                        <Column variant="zodiac.card">
                            <ZodiacCard variant={zodiacVariants.BETA} signList={signList} />
                        </Column>

                        {/* <ReadMoreWidget article={moreFromTheColumn?.[0]} /> */}
                        <Column variant="article.mightBeInterested">
                            <Media lessThan="md" scaleDown>
                                <Column variant="article.qpq" className="end-article zodiac">
                                    <ExchangeWidget
                                        cardIdPrefix="end_article"
                                        itemId={id}
                                        exchangeKey={widgetIdSets?.articleHigherWidget?.mobile}
                                        // title="Iz drugih medija new"
                                        titleSizeVariant="1x1"
                                        variant={exchangeWidgetVariants.IOTA}
                                    />
                                </Column>
                            </Media>
                            <YouMightBeInterestedBox excludeId={articleWithPreview.id} />
                        </Column>
                    </Row>

                    {/* ========= SIDEBAR ====== */}
                    <Row variant="article.mainContent.aside">
                        <Media greaterThan="sm" scaleDown className="Sidebar_aside">
                            <MoreFromTheColumnBox
                                variant={moreFromTheColumnBoxVariants.BETA}
                                items={moreFromTheColumn ?? []}
                                slice={[0, 3]}
                            />
                        </Media>
                    </Row>
                </Row>

                {productGallery && (
                    <Column variant="article.listalica">
                        <DynamicListalicaProizvoda
                            title={productGallery?.title}
                            items={productGallery?.extended_attributes?.product_galleries_products}
                            showLogo={!productGallery?.extended_attributes?.product_galleries_show_title}
                            logo={productGallery?.image}
                            titleUrl={productGallery?.extended_attributes?.product_galleries_url}
                        />
                    </Column>
                )}
                {/* @TODO: remove listalice after required dates  */}
                {/* <Column variant="article.listalica">
                    <RenderOnDay days={['wednesday', 'thursday']} years={['2023']}>
                        <AntiClsFrame minHeightXs="375px" minHeightMd="410px">
                            <DynamicListalicaProizvoda sourceVariant={listalicaProizvodaSourceVariants.T_HT} />
                        </AntiClsFrame>
                    </RenderOnDay>
                </Column> */}

                {articleWithPreview?.extended_attributes?.display_third_party_content && (
                    <Column variant="article.linker">
                        <AdNow />
                    </Column>
                )}

                {/* <Media lessThan="md" scaleDown>
                    <Column variant="article.listalica">
                        <DynamicListalicaProizvoda sourceVariant={listalicaProizvodaSourceVariants.NET_WEBSHOP} />
                    </Column>
                </Media> */}

                <Row variant="article.moreArticles">
                    <Media lessThan="md" scaleDown>
                        <MoreFromTheColumnBox
                            variant={moreFromTheColumnBoxVariants.BETA}
                            items={moreFromTheColumn ?? []}
                            slice={[3, 6]}
                        />
                    </Media>

                    <Media greaterThan="sm" scaleDown>
                        <MoreFromTheColumnBox
                            variant={moreFromTheColumnBoxVariants.ALPHA}
                            items={moreFromTheColumn ?? []}
                            slice={[3, 6]}
                        />
                    </Media>
                </Row>

                <Media greaterThan="sm" scaleDown>
                    <Row variant="article.facebook_ugc">
                        <Column variant="article.ugc">
                            <UserGeneratedContent hasTitleLineBreak variant="beta" />
                        </Column>
                    </Row>

                    {/* <Column variant="article.listalica">
                        <DynamicListalicaProizvoda sourceVariant={listalicaProizvodaSourceVariants.NET_WEBSHOP} />
                    </Column> */}
                </Media>
                <Media lessThan="md" scaleDown>
                    <Column variant="article.regional">
                        <ExchangeWidget
                            cardIdPrefix="regional_mobile"
                            itemId={id}
                            exchangeKey={widgetIdSets?.articleLowerWidget?.mobile}
                            // title="Iz drugih medija"
                            titleSizeVariant="1x1"
                            variant={exchangeWidgetVariants.EPSILON}
                        />
                    </Column>
                    <Column variant="article.ugc">
                        <UserGeneratedContent hasTitleLineBreak variant="beta" />
                    </Column>
                </Media>
                <Column variant="article.newsletter">
                    <Newsletter />
                </Column>
                <Column variant="article.regional">
                    <ExchangeWidget
                        cardIdPrefix="regional"
                        itemId={id}
                        exchangeKey={widgetIdSets?.regionalWidget}
                        title="Regionalni portali"
                        titleSizeVariant="1x1"
                        variant={exchangeWidgetVariants.DELTA}
                    />
                </Column>
                <Column variant="article.izdvojeno">
                    <SelectedArticlesBox excludeId={articleWithPreview.id} />
                </Column>

                {/* <Column variant="article.listalica">
                    <DynamicListalicaProizvoda sourceVariant={listalicaProizvodaSourceVariants.NET_WEBSHOP} />
                </Column> */}
            </Row>
        </BaseLayout>
    )
}

Zodiac.propTypes = {
    ssrData: PropTypes.shape({
        lengthScore: PropTypes.shape({
            score: PropTypes.number,
            lengthCategory: PropTypes.oneOf(['short', 'medium', 'long'])
        }),
        video: PropTypes.shape({}),
        article: PropTypes.shape({
            id: PropTypes.string
        }),
        videoElastic: PropTypes.shape({}),
        regional: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]),
        signList: PropTypes.arrayOf(PropTypes.shape({})),
        articleVideo: PropTypes.shape({}),
        moreFromTheColumn: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})]),
        isVideoPreview: PropTypes.bool
    }),
    navigation: PropTypes.shape({}).isRequired
}

Zodiac.defaultProps = {
    ssrData: undefined
}

Zodiac.displayName = 'Zodiac'

export default Zodiac
