import React, { useCallback, useEffect, useMemo } from 'react'
import { useUIDSeed } from 'react-uid'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { dotmetricsIDs } from '@hmn/rtl-web-core/components/analytics/Dotmetrics/Dotmetrics.component'
import { Column, Row } from '@hmn/rtl-web-core/components/layout'
import { Media } from '@hmn/rtl-web-core/components/Media'
import { useCrossDomainCommunication as usePreview, useGTM } from '@hmn/rtl-web-core/hooks'
import useEntityTypeDispatch from '@hmn/rtl-web-core/hooks/entity/useEntityTypeDispatch'
import { IsolatedEntityThirdParties } from '@hmn/rtl-web-core/hooks/IsolatedEntityThirdParties'
import { resetGTMEvents } from '@hmn/rtl-web-core/hooks/useGTM'
import { UUID_REGEXP } from '@hmn/rtl-web-core/next-config/regexp'

import { staticAds } from '@hmn/rtl-net-ui/components/Ad/config/gallery'
import AdSlot from '@hmn/rtl-net-ui/components/Ad/NetSlot.component'
import AdNow from '@hmn/rtl-net-ui/components/AdNow/AdNow.component'
import {
    ArticleCard,
    articleCardContentMarkerVariants,
    articleCardSizeVariants,
    articleCardVariants,
    ArticleCtaBlock
} from '@hmn/rtl-net-ui/components/Article/components'
import { GalleryCardsBlock, GalleryItem } from '@hmn/rtl-net-ui/components/Gallery/components'
import GalleryContent from '@hmn/rtl-net-ui/components/Gallery/components/Content/Content.component'
import { Meta } from '@hmn/rtl-net-ui/components/Meta'

import { BaseLayout } from '../../layouts'
import { Seo } from '..'
import { MostViewedGalleries } from '../MostViewedGalleries/MostViewedGalleries'

const {
    halfpage1,
    halfpage2,
    outOfPage1,
    outOfPage2,
    outOfPage3,
    anchor,
    interstitial,
    inGallery1,
    inGallery2,
    inGallery3
} = staticAds

const gtmDataNew = {
    eventCategory: 'Galerija',
    eventAction: 'Najnovije',
    eventLabel: 'Below content'
}

const entityRegex = new RegExp(`^${UUID_REGEXP}$`, 'gi')

function Gallery({ ssrData, navigation }) {
    useEntityTypeDispatch('gallery')
    const uid = useUIDSeed()
    const router = useRouter()
    let cleanPath

    let childId = (() => {
        const url = router.asPath
        const lastSlug = url.split('/').slice(-1)[0]

        if (lastSlug.match(entityRegex)) {
            cleanPath = router.asPath.split('/').slice(0, -1).join('/')
            return lastSlug
        }

        cleanPath = router.asPath

        return undefined
    })()

    const galleryData = ssrData.gallery
    const { id } = galleryData
    const { data: gallery } = usePreview(galleryData, { type: 'preview', source: '@CMS' })
    const { sendDataToGTM } = useGTM()

    // Get the gallery category slug and ID
    const galleryCategorySlug = galleryData?.taxons?.sitemap?.[0]?.path?.[0]?.slug || 'other'
    const galleryCategoryID = dotmetricsIDs[galleryCategorySlug]?.id || dotmetricsIDs.other.id

    // Send initial pageview with gallery category ID
    useEffect(() => {
        if (typeof window !== 'undefined' && window.dm) {
            if (galleryCategoryID) {
                // console.log(`Dotmetrics pageview sent with Gallery Category ID: ${galleryCategoryID}`)
                window.dm.AjaxEvent('pageview', null, galleryCategoryID)
            } else {
                // console.log('Gallery Category ID not found, defaulting to "other"')
                window.dm.AjaxEvent('pageview', null, dotmetricsIDs.other.id)
            }
        }
    }, [galleryCategoryID])

    const galleryCategory = galleryData?.sitemap?.node

    const { galleryImages } = ssrData
    const activeIndex = useMemo(() => {
        if (Array.isArray(galleryImages) && !childId) {
            return 0
        }
        const index = galleryImages?.findIndex(image => image.id === childId)
        return Math.max(0, index)
    }, [childId, galleryImages])

    const galleriesTags = useMemo(() => gallery?.tags || [], [gallery?.tags])

    // ******** GTM ********
    const latestGalleries = useMemo(() => {
        const galleryLatestIndex = ssrData.latestGalleries?.findIndex(galleryItem => galleryItem?.id === id)

        if (galleryLatestIndex === -1) {
            return ssrData.latestGalleries?.slice(0, 10)
        }

        return ssrData.latestGalleries?.filter(galleryItem => galleryItem?.id !== id)
    }, [ssrData.latestGalleries, id])

    const featuredImageId = gallery?.image?.id
    const showImageInfo = gallery?.extended_attributes?.gallery_show_image_info || false

    const handleSlideChange = index => {
        const imageId = galleryImages?.[index]?.id

        if (!imageId || index === activeIndex) {
            return
        }

        resetGTMEvents()

        const gallerySection = gallery?.taxons?.sitemap?.[0]?.path?.[0]?.title.toLowerCase()
        const { id: dotmetricsGalleryID } = dotmetricsIDs[`${gallerySection}Gallery`] || dotmetricsIDs.gallery

        // console.log('gallerySection', gallerySection, dotmetricsGalleryID)

        if (childId) {
            const newUrl = `${cleanPath}/${imageId}`

            if (window?.dm && dotmetricsGalleryID) {
                window.dm.AjaxEvent('pageview', null, dotmetricsGalleryID)
            }

            if (index % 3 === 0) {
                router.replace(newUrl, undefined, { shallow: true })
                return
            }
            const upScorePayload = {
                domain: 'net.hr',
                object_id: gallery?.id,
                section: gallery?.taxons?.sitemap?.[0]?.path?.[0]?.title || 'other',
                taxonomy: gallery?.taxons?.sitemap?.[0]?.path?.[1]?.title || 'other',
                url: window?.location?.href,
                object_type: 'gallery',
                sequence: index + 1,
                author: gallery?.creator?.display_name || gallery?.taxons?.authors?.[0]?.title || 'net.hr',
                pubdate: gallery?.extended_attributes?.published_at
                    ? new Date(gallery.extended_attributes.published_at).toISOString()
                    : null
            }
            if (window?.upScore) {
                window.upScore({ data: upScorePayload })
            }

            // Send Analytics data
            sendDataToGTM({
                event: 'VirtualPageView',
                eventCategory: 'Gallery',
                eventAction: `Standalone Gallery Slide ${index}`,
                eventLabel: 'Main',
                virtualPageTitle: gallery?.title,
                virtualPageURL: router.asPath
            })
            // window?.pp_gemius_hit(gallery?.sitemap?.node?.gemiusId || process.env.NEXT_PUBLIC_GEMIUS_ID_NET)

            window.marfeel = window.marfeel || { cmd: [] }
            window.marfeel.cmd.push([
                'compass',
                function compassFunction(compass) {
                    compass.trackNewPage({ rs: 'Article type Gallery' })
                }
            ])

            window.history.replaceState(null, '', newUrl)
            return
        }

        childId = imageId
        const newUrl = `${cleanPath}/${imageId}`
        window.history.replaceState(null, '', newUrl)

        if (window?.dm && dotmetricsGalleryID) {
            window.dm.AjaxEvent('pageview', null, dotmetricsGalleryID)
        }
        // window?.pp_gemius_hit(gallery?.sitemap?.node?.gemiusId || process.env.NEXT_PUBLIC_GEMIUS_ID_NET)

        window.marfeel = window.marfeel || { cmd: [] }
        window.marfeel.cmd.push([
            'compass',
            function compassFunction(compass) {
                compass.trackNewPage({ rs: 'Article type Gallery' })
            }
        ])
    }

    const handleFirstSlideClick = useCallback(() => {
        if (gallery?.prevNext?.prev?.uri) {
            resetGTMEvents()
            router.push(`${gallery?.prevNext?.prev?.uri}/${gallery?.prevNext?.prev?.last_image_id}`)
        }
    }, [gallery?.prevNext?.prev?.last_image_id, gallery?.prevNext?.prev?.uri, router])

    const handleLastSlideClick = useCallback(() => {
        if (gallery?.prevNext?.next?.uri) {
            resetGTMEvents()
            router.push(gallery?.prevNext?.next?.uri)
        }
    }, [gallery?.prevNext?.next?.uri, router])

    return (
        <BaseLayout navigationData={navigation} isGalleryPage>
            <IsolatedEntityThirdParties
                gallerySlideIndex={activeIndex}
                entity={gallery}
                childId={childId}
                entityType="gallery"
                appName="net.hr"
                defaultGemiusId={process.env.NEXT_PUBLIC_GEMIUS_ID_NET}
                appRoot={process.env.NEXT_PUBLIC_APP_ROOT_NET}
                ampGtmId={process.env.NEXT_PUBLIC_AMP_GTM_ID_NET}
            />
            <Seo mainEntity item={gallery} images={galleryImages} />
            <Column variant="media.main">
                <Row id="main_row">
                    <Column>
                        <Column variant="media.content">
                            <GalleryContent
                                key={gallery?.id}
                                handleLastSlideClick={handleLastSlideClick}
                                handleFirstSlideClick={handleFirstSlideClick}
                                activeIndex={activeIndex}
                                onChange={handleSlideChange}
                                subtitle={gallery?.subtitle || gallery?.extended_attributes?.subtitle}
                                title={gallery?.title}
                                lead={gallery?.description}
                                categoryColor={galleryCategory?.categoryColor}
                                adSlotConfig={{ ...inGallery1 }}>
                                {galleryImages?.map((image, index) => {
                                    const isFeaturedImage = image.id === featuredImageId
                                    return (
                                        <GalleryItem
                                            key={image.id}
                                            image={image}
                                            showImageInfo={!isFeaturedImage && showImageInfo}
                                            slideIndex={index}
                                            activeIndex={activeIndex}
                                            totalSlides={galleryImages.length}
                                        />
                                    )
                                })}
                            </GalleryContent>
                        </Column>
                        <Column variant="media.ads.billboard">
                            <AdSlot {...inGallery2} desktopOnly />
                        </Column>
                        {gallery?.extended_attributes?.cta_title && gallery?.extended_attributes?.cta_url ? (
                            <Column variant="media.cta">
                                <ArticleCtaBlock
                                    content={gallery?.extended_attributes?.cta_title}
                                    title="Saznaj više o temi"
                                    href={gallery?.extended_attributes?.cta_url}
                                    buttonTitle={gallery?.extended_attributes?.cta_button_label}
                                />
                            </Column>
                        ) : null}
                        <Column variant="media.meta" id="media_meta">
                            <Meta
                                author={
                                    gallery?.taxons?.authors?.[0]?.title ||
                                    gallery?.extended_attributes?.author ||
                                    'net.hr'
                                }
                                authors={gallery?.taxons?.authors || gallery?.extended_attributes?.author || []}
                                photoAuthor={gallery?.extended_attributes?.source}
                                date={gallery?.published_at}
                                tags={galleriesTags}
                                authorSlug={gallery?.taxons?.authors?.[0]?.slug}
                            />
                        </Column>
                    </Column>
                    <Column variant="media.main.aside" id="aside">
                        <Media className="Sidebar_aside" greaterThan="sm" scaleDown>
                            <AdSlot {...halfpage1} deviceProp="desktop" />
                        </Media>
                    </Column>
                </Row>

                {gallery?.extended_attributes?.display_third_party_content && (
                    <Column variant="article.linker" id="linker_desktop">
                        <AdNow />
                    </Column>
                )}

                <Row variant="media.ads.billboard">
                    <AdSlot {...inGallery3} desktopOnly />
                </Row>

                <Row variant="media.relatedMobile">
                    <Column variant="media.related.latest">
                        <GalleryCardsBlock title="Najnovije" titleTagComponent="h4" href="/media/fotogalerije">
                            {latestGalleries?.map((item, index) => (
                                <ArticleCard
                                    contentMarkerVariant={articleCardContentMarkerVariants.GALLERY}
                                    item={item}
                                    key={uid(item?.id || index)}
                                    hrefAs={item?.sitemap?.href}
                                    variant={articleCardVariants.ALPHA}
                                    sizeVariant={articleCardSizeVariants['1x2']}
                                    imageWidth={700}
                                    imageHeight={466}
                                    gtmData={gtmDataNew}
                                />
                            ))}
                        </GalleryCardsBlock>
                    </Column>

                    <Media lessThan="md" scaleDown>
                        <Column variant="media.ads.billboard">
                            <AdSlot {...inGallery3} mobileOnly />
                        </Column>
                    </Media>

                    <Column variant="media.related.popular" id="najgledanije">
                        <Row>
                            <MostViewedGalleries excludeId={gallery.id} />
                        </Row>
                        <Row variant="media.ads.floating">
                            <Media className="Sidebar_aside" greaterThan="sm" scaleDown>
                                <AdSlot {...halfpage2} />
                            </Media>
                        </Row>
                    </Column>
                </Row>
            </Column>

            <Row variant="zeroDimensions">
                <AdSlot {...outOfPage1} />
                <AdSlot {...outOfPage2} />
                <AdSlot {...outOfPage3} />
                <AdSlot {...anchor} />
                <AdSlot {...interstitial} />
            </Row>
        </BaseLayout>
    )
}

Gallery.propTypes = {
    themeName: PropTypes.string.isRequired,
    ssrData: PropTypes.shape({
        gallery: PropTypes.shape({
            id: PropTypes.string,
            sitemap: PropTypes.shape({
                node: PropTypes.shape({})
            }),
            published_at: PropTypes.string,
            taxons: PropTypes.shape({
                sitemap: PropTypes.arrayOf(
                    PropTypes.shape({
                        path: PropTypes.arrayOf(
                            PropTypes.shape({
                                slug: PropTypes.string
                            })
                        )
                    })
                )
            })
        }).isRequired,
        galleryImages: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string
            })
        ).isRequired,
        mostViewedGalleries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        latestGalleries: PropTypes.arrayOf(PropTypes.shape({})).isRequired
    }).isRequired,
    context: PropTypes.shape({
        embeds: PropTypes.shape({
            entities: PropTypes.shape({}).isRequired
        }).isRequired
    }).isRequired,
    navigation: PropTypes.shape({}).isRequired
}

Gallery.displayName = 'Gallery'

export default Gallery
